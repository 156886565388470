import React from "react"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"

const LocalBusinessSchema = ({ company }) => {
  const { pathname } = useLocation()
  const pageUrl = `https://taho.org.ua${pathname}`
  const schemaData = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    name: `${company.companyType} '${company.shortname}'`,
    // image: `${window.location.origin}/path-to-your-image.jpg`, // Replace with actual image path
    telephone: company.phones.split(",")[0], // Assumes the first phone number is primary
    address: {
      "@type": "PostalAddress",
      streetAddress: company.address,
      addressLocality: company.mapCity,
      addressRegion: company.mapObl,
      postalCode: company.mapZip,
      addressCountry: "UK", // Replace with actual country code if available
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: company.mapLat,
      longitude: company.mapLong,
    },
    url: company.website ? company.website : pageUrl,
    // sameAs: [],
    // Add additional properties as needed
  }

  return (
    <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
  )
}

LocalBusinessSchema.propTypes = {
  company: PropTypes.object.isRequired,
}

export default LocalBusinessSchema
