import React from "react"
import { ChipIcon, CogIcon, ScaleIcon } from "@heroicons/react/outline"

const CompanyServices = ({
  companyShortName,
  analogServ,
  digitServ,
  verifServ,
}) => {
  const serviceAnalog = analogServ === "TRUE" ? true : false
  const serviceDigital = digitServ === "TRUE" ? true : false
  const serviceVetification = verifServ === "TRUE" ? true : false
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 py-12 sm:py-24">
      <div className="text-4xl font-medium mb-8">Послуги</div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <span>
          <p className="mb-4">
            {`Згідно з реєстром Укртрансбезпеки, компанія ${companyShortName} сертифікована для виконання робот:`}
          </p>
          {serviceAnalog && (
            <div className="text-2xl mb-4">
              <CogIcon className="text-yellow-600 h-6 w-auto inline-block mr-2" />
              Обслуговування аналогових тахографів
            </div>
          )}
          {serviceDigital && (
            <div className="text-2xl mb-4">
              <ChipIcon className="text-yellow-600 h-6 w-auto inline-block mr-2" />
              Обслуговування цифрових тахографів
            </div>
          )}
          {serviceVetification && (
            <div className="text-2xl mb-4">
              <ScaleIcon className="text-yellow-600 h-6 w-auto inline-block mr-2" />
              Повірка тахографів
            </div>
          )}
        </span>
        <span>
          <p className="mb-4">
            Після встановлення цифрового тахографа й&nbsp;отримання державного
            реєстраційного номера, протягом 7&nbsp;днів необхідно провести
            калібрування.
          </p>
          <p className="mb-4">
            Обслуговування необхідно проводити не&nbsp;рідше, ніж раз
            на&nbsp;24&nbsp;місяці, також після заміни шин та&nbsp;агрегатів
            коробки передач.
          </p>
          <p className="mb-4">
            Калібрування здійснюється відповідно до&nbsp;вимог ЄУТР,
            за&nbsp;допомогою спеціального обладнання на&nbsp;сертифікованних
            сервісних центрах. Тахограф що&nbsp;не&nbsp;проходив калібрування
            вважається несправним.
          </p>
          <p className="mb-4">
            За&nbsp;Законом України &laquo;Про метрологію та&nbsp;метрологічну
            діяльність&raquo;, обов&rsquo;язкова повірка цифрових тахографів
            виконується раз на&nbsp;6&nbsp;років, а&nbsp;аналогових&nbsp;&mdash;
            раз на&nbsp;4&nbsp;роки. Повірку не&nbsp;слід плутати
            з&nbsp;калібруванням.
          </p>
          <p className="mb-4">
            Для проведення повірки тахограф демонтується,
            а&nbsp;після&nbsp;&mdash; встановлюється на&nbsp;місце
            та&nbsp;пломбується. Ці&nbsp;роботи виконуються лише сертифікованими
            компаніями.
          </p>
        </span>
      </div>
    </div>
  )
}

export default CompanyServices
