import React from "react"
import { graphql } from "gatsby"

import { UserCircleIcon } from "@heroicons/react/outline"

// import { slugify } from "../utils/transcriptSlug"
import { formatPhoneNumber } from "../utils/phoneFormater"

import Layout from "../components/layout"
import Seo from "../components/global/seo"
import BreadCrumbs from "../components/global/BreadCrumbs"
import Map from "../components/LeafletMap"
import RelatedCompanies from "../components/company/related"
import Schedule from "../components/company/schedule"
import Contacts from "../components/company/contacts"
import Services from "../components/company/services"
import LocalBusinessSchema from "../components/schema/LocalBusiness"

export const query = graphql`
  query ($id: String!, $serviceNumber: String!, $cityName: String!) {
    tahoservCsv(id: { eq: $id }) {
      shortname
      title
      id
      idNumber
      serviceNumber
      address
      analogServ
      digitServ
      verifServ
      comments
      companyType
      emails
      phones
      slug
      website
      mapZip
      mapObl
      mapOblSlug
      mapOblChanged
      mapDistrict
      mapCity
      mapCitySlug
      mapBuilding
      mapLat
      mapLong
      lastSeen
    }
    allSpecialistsCsv(filter: { profServiceNumber: { eq: $serviceNumber } }) {
      nodes {
        profID
        profFullName
        profServiceNumber
        id
      }
    }
    allTahoservCsv(sort: { fields: sortID, order: DESC }) {
      nodes {
        id
        slug
        title
        shortname
        mapObl
        mapCity
        analogServ
        digitServ
        verifServ
      }
    }
    mapCityCsv(cityName: { eq: $cityName }) {
      cityName
      cityNameChanged
      cityObl
      cityOblChanged
      cityType
      cityTypeChanged
      cityTypeChanged2
      cityTypeShort
      slug
    }
  }
`

const Company = ({ data }) => {
  const company = data.tahoservCsv
  const allCompanies = data.allTahoservCsv.nodes
  const specialists = data.allSpecialistsCsv.nodes
  const cityData = data.mapCityCsv

  // Phone Number Array no spaces, no empty values
  // and not includes fax numbers
  const phonesArray = company.phones
    .split(",")
    .map(phone => phone.trim())
    .filter(v => v)
    .filter(p => !p.includes("ф"))
  // Only cleared fax phone numbers
  // (originaly include "ф." inside phone string)
  const faxArray = company.phones
    .split(",")
    .map(phone => phone.trim())
    .filter(v => v)
    .filter(p => p.includes("ф"))

  const companyBreadCrumbs = [
    { position: 1, name: "Тахографи", url: "/", isLast: false },
    { position: 2, name: "Компанії", url: "/kompaniyi/", isLast: false },
    {
      position: 3,
      name: `${company.mapObl} область`,
      url: `/kompaniyi${company.mapOblSlug}`,
      isLast: false,
    },
    {
      position: 4,
      name: company.mapCity,
      url: `/kompaniyi${company.mapCitySlug}`,
      isLast: false,
    },
    { position: 5, name: company.shortname, url: "/", isLast: true },
  ]

  return (
    <>
      <Layout>
        <Seo
          title={`${company.shortname} | ${company.serviceNumber}`}
          description={`Компанія ${company.shortname}, ${company.serviceNumber} в місті ${company.mapCity}, ${company.mapOblChanged} області з продажу, обслуговання і ремонту тахографів на порталі для перевізників taho.org.ua.`}
        />
        <main itemScope={true} itemType="https://schema.org/Organization">
          <div className="bg-yellow-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
              <BreadCrumbs breadcrumbs={companyBreadCrumbs} />
            </div>

            <div className="container mx-auto flex flex-col items-center py-12 sm:py-24">
              <div className="w-11/12 sm:w-2/3 mb-5 sm:mb-10">
                <h1
                  className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-yellow-900 font-extrabold leading-tight"
                  itemProp="name"
                >
                  {`${company.shortname}, ${company.mapCity}`}
                </h1>
                <p className="mt-5 sm:mt-10 text-gray-600 font-normal text-center text-lg sm:text-lg">
                  Компанія {company.title} внесена до&nbsp;офіційного реєстру
                  суб’єктів господарювання, які здійснюють встановлення, ремонт
                  та&nbsp;технічне обслуговування тахографів. Пункт сервісу
                  номер&nbsp;{company.serviceNumber} у&nbsp;відкритому реєстрі
                  Укртрасбезбеки від&nbsp;
                  <span className="whitespace-nowrap mr-1">
                    {company.lastSeen}.
                  </span>
                  {company.comments && (
                    <span className="inline-block items-center justify-center px-2 py-1 text-yellow-600 bg-yellow-100 rounded">
                      Зверніть увагу: {company.comments}
                    </span>
                  )}
                </p>
              </div>
              <div className="flex justify-center items-center">
                {phonesArray.length > 0
                  ? phonesArray.map(phone => (
                      <a
                        key={phone}
                        href={`tel:+38${phone.replace(/\D/g, "")}`}
                        className="ml-4 focus:outline-none bg-white transition duration-150 ease-in-out hover:border-yellow-600 hover:text-yellow-600 rounded border border-yellow-700 text-yellow-700 px-4 sm:px-10 py-2 sm:py-4 text-sm"
                      >
                        {`+38${formatPhoneNumber(phone)}`}
                      </a>
                    ))
                  : faxArray.length > 0 &&
                    faxArray.map(fax => (
                      <a
                        key={fax}
                        href={`tel:+38${fax.replace(/\D/g, "")}`}
                        className="ml-4 focus:outline-none bg-white transition duration-150 ease-in-out hover:border-yellow-600 hover:text-yellow-600 rounded border border-yellow-700 text-yellow-700 px-4 sm:px-10 py-2 sm:py-4 text-sm"
                      >
                        {`+38${formatPhoneNumber(fax)}`}
                      </a>
                    ))}
              </div>
            </div>
          </div>

          <Services
            companyShortName={company.shortname}
            analogServ={company.analogServ}
            digitServ={company.digitServ}
            verifServ={company.verifServ}
          />

          <div className="max-w-7xl mx-auto px-4 sm:px-6 py-12 sm:py-24">
            <div className="text-4xl font-medium mb-12">Контакти</div>
            <div className="grid grid-cols-1 md:grid-cols-2 ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-0 sm:gap-x-6">
                <Contacts
                  fullAddress={company.address}
                  staff={specialists.nodes}
                  faxArray={faxArray}
                  phonesArray={phonesArray}
                  email={company.emails}
                  mapLat={company.mapLat}
                  mapLong={company.mapLong}
                  comment={company.comments}
                />
                <Schedule />
              </div>
              <div>
                {typeof window !== "undefined" && (
                  <Map
                    position={[company.mapLat, company.mapLong]}
                    center={[company.mapLat, company.mapLong]}
                    zoom={13}
                    markerText={
                      data.tahoservCsv.mapLat && data.tahoservCsv.mapLong ? (
                        <a
                          href={`http://www.google.com/maps/place/${data.tahoservCsv.mapLat},${data.tahoservCsv.mapLong}`}
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          className="block"
                        >
                          {`${data.tahoservCsv.title} в Google`}
                        </a>
                      ) : (
                        data.tahoservCsv.title
                      )
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 py-12 sm:py-24">
            <div className="text-4xl font-medium mb-12">
              Фахівці пункту сервісу {company.serviceNumber}
            </div>
            <ul
              itemProp="alumni"
              itemScope={true}
              itemType="https://schema.org/Person"
            >
              {specialists.map(spec => (
                <li key={spec.id} itemProp="name">
                  <UserCircleIcon className="inline-block h-10 w-10 text-yellow-700 mr-2" />
                  {spec.profFullName}
                </li>
              ))}
            </ul>
          </div>
        </main>

        <RelatedCompanies
          companyShortName={company.shortname}
          city={company.mapCity}
          cityNameChanged={cityData.cityNameChanged}
          oblast={company.mapObl}
          oblastChanged={company.mapOblChanged}
          ownId={company.id}
          allCompanies={allCompanies}
        />
      </Layout>
      <LocalBusinessSchema company={company} />
    </>
  )
}

export default Company
