import React from "react"
import PropTypes from "prop-types"

import CompaniesList from "../catalog/catalogList"

const RelatedCompanies = ({
  companyShortName,
  city,
  cityNameChanged,
  oblast,
  oblastChanged,
  ownId,
  allCompanies,
}) => {
  const relatedByName = allCompanies
    .filter(related => related.shortname === companyShortName)
    .filter(c => c.id !== ownId)
  const relatedByCity = allCompanies
    .filter(related => related.mapCity === city)
    .filter(c => c.id !== ownId)
  const relatedByOblast = allCompanies
    .filter(related => related.mapObl === oblast)
    .filter(c => c.id !== ownId)

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 py-12 sm:py-24">
      {relatedByName.length > 0 && (
        <>
          <div className="text-4xl font-medium mb-12">
            Інші відділення компанії {companyShortName}
          </div>
          <CompaniesList companies={relatedByName} />
        </>
      )}

      {relatedByCity.length > 0 ? (
        <>
          <div className="text-4xl font-medium mb-12">
            Ще компанії в {cityNameChanged}
          </div>
          <CompaniesList companies={relatedByCity.slice(0, 3)} />
        </>
      ) : (
        <>
          <div className="text-4xl font-medium mb-12">
            Ще компанії {oblastChanged} області
          </div>
          <CompaniesList companies={relatedByOblast.slice(0, 3)} />
        </>
      )}

      {/* {relatedByOblast.length > 0 && (
        <>
          <div className="text-4xl font-medium mt-8 mb-4">
            Компанії {oblastChanged} області
          </div>
          <CompaniesList companies={relatedByOblast.slice(0, 3)} />
        </>
      )} */}
    </div>
  )
}

RelatedCompanies.propTypes = {
  companyShortName: PropTypes.string,
  city: PropTypes.string,
  oblast: PropTypes.string,
  ownId: PropTypes.string,
  allCompanies: PropTypes.array,
}

RelatedCompanies.defaultProps = {
  companyShortName: "DEFAULT NAME",
  city: "DEFAULT CITY",
  oblast: "DEFAULT REGION",
  ownId: null,
  allCompanies: [],
}

export default RelatedCompanies
