import React, { Component } from "react"
import PropTypes from "prop-types"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
// import "./leafletmap.css"

class LeafletMap extends Component {
  static propTypes = {
    /** Latitude and Longitude of the map centre in an array, eg [51, -1] **/
    position: PropTypes.array,

    /** Initial zoom level for the map (default 13) **/
    zoom: PropTypes.number,

    /** If set, will display a marker, which when clicked will display this text **/
    markerText: PropTypes.element,
  }

  static defaultProps = {
    position: [49.3241712, 29.5735713],
    zoom: 7,
    markerText: "Компанія",
  }

  render() {
    return (
      <MapContainer
        center={this.props.position}
        zoom={this.props.zoom}
        className="z-0"
      >
        <TileLayer
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {this.props.markerText !== "" && (
          <Marker position={this.props.position}>
            <Popup>{this.props.markerText}</Popup>
          </Marker>
        )}
      </MapContainer>
    )
  }
}

export default LeafletMap
