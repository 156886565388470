import React from "react"
import PropTypes from "prop-types"

import { formatPhoneNumber } from "../../utils/phoneFormater"

const CompanyContacts = ({
  fullAddress,
  faxArray,
  phonesArray,
  email,
  staff,
  mapLat,
  mapLong,
  // comment,
}) => {
  return (
    <div>
      <div className="mb-12">
        <div className="text-2xl font-medium mb-6">Адреса</div>
        <div
          itemProp="address"
          itemScope={true}
          itemType="https://schema.org/PostalAddress"
        >
          <span itemProp="streetAddress">
            {fullAddress.replace(/,\s*$/, "")}.
          </span>

          {mapLat && mapLong ? (
            <a
              href={`http://www.google.com/maps/place/${mapLat},${mapLong}`}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="block text-yellow-700 underline hover:text-yellow-900"
            >
              Відкрити на Google Картах
            </a>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="mb-12">
        <div className="text-2xl font-medium mb-6">Зв'язок</div>
        {phonesArray?.length > 0 && (
          <ul>
            {phonesArray.map(phone => (
              <li key={phone}>
                <a
                  className=" text-yellow-700 underline hover:text-yellow-900"
                  href={`tel:+38${phone.replace(/\D/g, "")}`}
                  itemProp="telephone"
                >{`+38${formatPhoneNumber(phone)}`}</a>
              </li>
            ))}
          </ul>
        )}

        {faxArray?.length > 0 && (
          <div>
            {faxArray.map(phone => (
              <span key={phone} itemProp="faxNumber">{`+38${formatPhoneNumber(
                phone
              )} (факс)`}</span>
            ))}
          </div>
        )}

        {email && <div itemProp="email">{email.toLowerCase()}</div>}
      </div>

      {/* {staff.lengh > 0 && (
        <>
          <h2 className="text-md font-medium mt-8 mb-4">
            Сетификовані фахівці
          </h2>
          <ul>
            {staff.map(spec => (
              <li key={spec.id}>
                <div className="inline-block h-10 w-10 rounded-full ring-2 ring-white bg-yellow-500"></div>
                {spec.profFullName}
              </li>
            ))}
          </ul>
        </>
      )} */}
    </div>
  )
}

CompanyContacts.defaultProps = {
  fullAddress: PropTypes.string,
  phonesArray: PropTypes.array,
  faxArray: PropTypes.array,
  staff: PropTypes.array,
  email: PropTypes.string,
  mapLat: PropTypes.string,
  mapLong: PropTypes.string,
  comment: PropTypes.string,
}

CompanyContacts.defaultProps = {
  fullAddress: "DEFAULT Full Address",
  phonesArray: null,
  faxArray: null,
  staff: ["DEFAULT Specialist Full Name"],
  email: null,
  mapLat: null,
  mapLong: null,
  comment: null,
}

export default CompanyContacts
