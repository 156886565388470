import React from "react"

const CompanySchedule = () => {
  return (
    <div className="mb-12">
      <h2 className="text-2xl font-medium mb-6">Графік роботи</h2>
      <ul>
        <li>
          <span>Понеділок:</span> <span className="">09:00-18:00</span>
        </li>
        <li>
          <span>Вівторок:</span> <span className="">09:00-18:00</span>
        </li>
        <li>
          <span>Середа:</span> <span className="">09:00-18:00</span>
        </li>
        <li>
          <span>Четвер:</span> <span className="">09:00-18:00</span>
        </li>
        <li>
          <span>П'ятниця:</span> <span className="">09:00-18:00</span>
        </li>
        <li>
          <span>Субота:</span> <span className="">вихідний</span>
        </li>
        <li>
          <span>Неділя:</span> <span className="">вихідний</span>
        </li>
      </ul>
    </div>
  )
}

export default CompanySchedule
